class RequestFailedError extends Error {
  constructor(response) {
    super('Request failed');

    this.name = 'RequestFailedError';
    this.response = response;
    this.status = response.status;
    this.httpCode = response.httpCode;

    Object.setPrototypeOf(this, RequestFailedError.prototype);
  }
}

export default RequestFailedError;
